import { useAppDispatch, useAppSelector } from "features/hooks";
import { setDataHouseModelStorm } from "features/houseModel";
import { RootState } from "features/store";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import styles from "../Forms.module.scss";

const HouseModelStormForm: FC = () => {
  const dispatch = useAppDispatch();
  const data: string = useAppSelector(
    (state: RootState) => state.houseModel.data.storm
  );
  const [value, setValue] = useState<string>(data);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setDataHouseModelStorm(value));
    alert("Đã lưu!");
  };

  return (
    <div className={styles.contentWrap}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          Quản trị <span className={styles.strong}>rủi ro</span>
        </h3>
        <p className={styles.subtitle}>
          Nhận diện và giảm thiểu trước khi quá trễ
        </p>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formControl}>
            <label className={styles.label}>
              Đâu là các yếu tố rủi ro sẽ cản trở bạn?
            </label>
            <textarea
              className={styles.input}
              name="storm"
              onChange={handleChange}
              rows={10}
            >
              {value}
            </textarea>
          </div>
          <button type="submit" className={styles.btn}>
            Lưu lại
          </button>
        </form>
      </div>
    </div>
  );
};

export default HouseModelStormForm;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  TStrategiesModel,
  TStrategiesModelState,
} from "./strategiresTreeModel.types";

const dataDefault: TStrategiesModel = {
  branches: "",
};

// Define the initial state using that type
const initialState: TStrategiesModelState = {
  data: dataDefault,
};

export const swotModelSlice = createSlice({
  name: "strategiesModel",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDataStrategiesModelBranches: (state, action: PayloadAction<string>) => {
      state.data.branches = action.payload;
    },
    setDataStrategiesModel: (
      state,
      action: PayloadAction<TStrategiesModel>
    ) => {
      state.data = action.payload;
    },
  },
});

export const { setDataStrategiesModelBranches, setDataStrategiesModel } =
  swotModelSlice.actions;

export default swotModelSlice.reducer;

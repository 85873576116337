import { useAppDispatch, useAppSelector } from "features/hooks";
import { setDataHouseModelStairs } from "features/houseModel";
import { THouseModelStairs } from "features/houseModel/houseModel.types";
import { RootState } from "features/store";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import styles from "../Forms.module.scss";

const HouseModelStairsForm: FC = () => {
  const dispatch = useAppDispatch();
  const data: THouseModelStairs = useAppSelector(
    (state: RootState) => state.houseModel.data.stairs
  );
  const [value, setValue] = useState<THouseModelStairs>({
    first: data?.first || "",
    second: data?.second || "",
    third: data?.third || "",
    fourth: data?.fourth || "",
    last: data?.last || "",
  });

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue({
      ...value,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setDataHouseModelStairs(value));
    alert("Đã lưu!");
  };

  return (
    <div className={styles.contentWrap}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          Chiến lược lên các cấp <span className={styles.strong}>cao hơn</span>
        </h3>
        <p className={styles.subtitle}>
          Phát hiện tiềm năng và hành động thích đáng
        </p>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formControl}>
            <label className={styles.label}>Chiến lược cấp 1</label>
            <textarea
              className={styles.input}
              name="first"
              onChange={handleChange}
              rows={10}
            >
              {value.first}
            </textarea>
          </div>
          <div className={styles.formControl}>
            <label className={styles.label}>Chiến lược cấp 2</label>
            <textarea
              className={styles.input}
              name="second"
              onChange={handleChange}
              rows={8}
            >
              {value.second}
            </textarea>
          </div>
          <div className={styles.formControl}>
            <label className={styles.label}>Chiến lược cấp 3</label>
            <textarea
              className={styles.input}
              name="third"
              onChange={handleChange}
              rows={6}
            >
              {value.third}
            </textarea>
          </div>
          <div className={styles.formControl}>
            <label className={styles.label}>Chiến lược cấp 4</label>
            <textarea
              className={styles.input}
              name="fourth"
              onChange={handleChange}
              rows={3}
            >
              {value.fourth}
            </textarea>
          </div>
          <div className={styles.formControl}>
            <label className={styles.label}>Chiến lược cuối cùng</label>
            <textarea
              className={styles.input}
              name="last"
              onChange={handleChange}
              rows={1}
            >
              {value.last}
            </textarea>
          </div>
          <button type="submit" className={styles.btn}>
            Lưu lại
          </button>
        </form>
      </div>
    </div>
  );
};

export default HouseModelStairsForm;

import Dashboard from "components/Dashboard";
import Helper from "components/Helper";
import HouseModelAuthorForm from "components/HouseModel/Forms/HouseModelAuthorForm";
import HouseModelGoalsForm from "components/HouseModel/Forms/HouseModelGoalsForm";
import HouseModelResourcesForm from "components/HouseModel/Forms/HouseModelResourcesForm";
import HouseModelShineForm from "components/HouseModel/Forms/HouseModelShineForm";
import HouseModelStairsForm from "components/HouseModel/Forms/HouseModelStairsForm";
import HouseModelStormForm from "components/HouseModel/Forms/HouseModelStormForm";
import HouseModelValuesForm from "components/HouseModel/Forms/HouseModelValuesForm";
import HouseModelRoot from "components/HouseModel/HouseModelRoot";
import StrategiesTreeModel from "components/StrategiesTreeModel";
import StrategiesTreeModelBranchesForm from "components/StrategiesTreeModel/StrategiesTreeModelForm/StrategiesTreeModelBranchesForm";
import StrategiesTreeModelRoot from "components/StrategiesTreeModel/StrategiesTreeModelRoot";
import SWOTModel from "components/SWOTModel";
import SWOTModelOForm from "components/SWOTModel/SWOTForms/SWOTModelOForm";
import SWOTModelSForm from "components/SWOTModel/SWOTForms/SWOTModelSForm";
import SWOTModelTForm from "components/SWOTModel/SWOTForms/SWOTModelTForm";
import SWOTModelWForm from "components/SWOTModel/SWOTForms/SWOTModelWForm";
import SWOTModelRoot from "components/SWOTModel/SWOTModelRoot";
import { createBrowserRouter, Outlet, RouterProvider } from "react-router-dom";
import routes from "utils/routes";
import HouseModel from "./components/HouseModel";
import Navigation from "./components/Navigation";
import Wrapper from "./components/Wrapper";

const Root = () => {
  return (
    <>
      <Navigation />
      <Wrapper>
        <Outlet />
      </Wrapper>
    </>
  );
};

const router = createBrowserRouter([
  {
    path: "",
    element: <Root />,
    children: [
      {
        path: routes.DASHBOARD,
        element: <Dashboard />,
      },
      {
        path: routes.HOUSE_MODEL,
        element: <HouseModelRoot />,
        children: [
          {
            path: routes.HOUSE_MODEL_DASHBOARD,
            element: <HouseModel />,
          },
          {
            path: routes.HOUSE_MODEL + routes.HOUSE_MODEL_AUTHOR,
            element: <HouseModelAuthorForm />,
          },
          {
            path: routes.HOUSE_MODEL + routes.HOUSE_MODEL_RESOURCES,
            element: <HouseModelResourcesForm />,
          },
          {
            path: routes.HOUSE_MODEL + routes.HOUSE_MODEL_SHINE,
            element: <HouseModelShineForm />,
          },
          {
            path: routes.HOUSE_MODEL + routes.HOUSE_MODEL_STORM,
            element: <HouseModelStormForm />,
          },
          {
            path: routes.HOUSE_MODEL + routes.HOUSE_MODEL_VALUES,
            element: <HouseModelValuesForm />,
          },
          {
            path: routes.HOUSE_MODEL + routes.HOUSE_MODEL_GOALS,
            element: <HouseModelGoalsForm />,
          },
          {
            path: routes.HOUSE_MODEL + routes.HOUSE_MODEL_STAIRS,
            element: <HouseModelStairsForm />,
          },
        ],
      },
      {
        path: routes.SWOT_MODEL,
        element: <SWOTModelRoot />,
        children: [
          {
            path: routes.SWOT_MODEL_DASHBOARD,
            element: <SWOTModel />,
          },
          {
            path: routes.SWOT_MODEL + routes.SWOT_MODEL_STRENGTHS,
            element: <SWOTModelSForm />,
          },
          {
            path: routes.SWOT_MODEL + routes.SWOT_MODEL_WEAKNESSES,
            element: <SWOTModelWForm />,
          },
          {
            path: routes.SWOT_MODEL + routes.SWOT_MODEL_OPPORTUNITIES,
            element: <SWOTModelOForm />,
          },
          {
            path: routes.SWOT_MODEL + routes.SWOT_MODEL_THREATS,
            element: <SWOTModelTForm />,
          },
        ],
      },
      {
        path: routes.STRATEGIES_TREE_MODEL,
        element: <StrategiesTreeModelRoot />,
        children: [
          {
            path: routes.STRATEGIES_TREE_MODEL_DASHBOARD,
            element: <StrategiesTreeModel />,
          },
          {
            path:
              routes.STRATEGIES_TREE_MODEL +
              routes.STRATEGIES_TREE_MODEL_BRANCHS,
            element: <StrategiesTreeModelBranchesForm />,
          },
        ],
      },
      {
        path: routes.HELPER,
        element: <Helper />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;

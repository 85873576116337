import { FC } from "react";
import { Text } from "react-konva";
import map from "lodash-es/map";
import paperSize from "utils/paperSize";
import houseModelConfig from "utils/houseModelConfig";
import split from "lodash-es/split";

type THouseResourcesProps = {
  text?: string;
};

const fontSize = houseModelConfig.fontSize;
const lineHeight = houseModelConfig.lineHeight;

const HouseResources: FC<THouseResourcesProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <>
      {map(split(text, "\n"), (label: string, key: number) => (
        <Text
          key={key}
          width={paperSize.width / 2}
          fontSize={fontSize}
          lineHeight={lineHeight}
          align="center"
          x={paperSize.width / 2 - (paperSize.width * 6.5) / 100}
          y={
            paperSize.height -
            (paperSize.height / 2 -
              (paperSize.height * 5.2) / 100 -
              key * ((paperSize.height * 4) / 100))
          }
          text={label}
          fill="black"
        />
      ))}
    </>
  );
};

export default HouseResources;

import { FC } from "react";
import { Text, Line, Circle } from "react-konva";
import houseModelConfig from "utils/houseModelConfig";
import paperSize from "utils/paperSize";

type TFourGoalsProps = {
  text?: string;
};

const fontSize = houseModelConfig.fontSize;
const lineHeight = houseModelConfig.lineHeight;
const textColor = houseModelConfig.colorGlobals[3];
const guildeCircleSize = 2;
const guildeLineColor = houseModelConfig.colorGlobals[3];
const x = 10;
const y = (paperSize.height * 45) / 100;
const w = paperSize.width / 5.5;
const h = fontSize * lineHeight * 10;
const guildePoints = [
  (paperSize.width * 38) / 100,
  (paperSize.height * 51) / 100,
  x + w + 30,
  y + fontSize / 2,
  x + w + 10,
  y + fontSize / 2,
];

const FourGoals: FC<TFourGoalsProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <>
      <Circle
        x={guildePoints[0]}
        y={guildePoints[1]}
        radius={guildeCircleSize}
        fill={guildeLineColor}
      />
      <Circle
        x={guildePoints[4]}
        y={guildePoints[5]}
        radius={guildeCircleSize}
        fill={guildeLineColor}
      />
      <Line
        points={guildePoints}
        stroke={guildeLineColor}
        strokeWidth={1}
        lineJoin="round"
        dash={[3, 2]}
      />
      <Text
        x={x}
        y={y}
        width={w}
        height={h}
        align="right"
        fontSize={fontSize}
        lineHeight={lineHeight}
        text={text}
        fill={textColor}
      />
    </>
  );
};

export default FourGoals;

import dayjs from "dayjs";
import { FC } from "react";
import styles from "./Helper.module.scss";

const Helper: FC = () => {
  return (
    <div className={styles.pageWrap}>
      <p>© {dayjs().format("YYYY")} Swiss Create</p>
    </div>
  );
};

export default Helper;

import clsx from "clsx";
import map from "lodash-es/map";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import routes from "utils/routes";
import IconModule from "shared/icons/IconModule";
import styles from "./Navigation.module.scss";
import IconHelper from "shared/icons/IconHelper";

const navs = [
  {
    label: "Dashboard",
    icon: <IconModule />,
    link: routes.DASHBOARD,
  },
  {
    label: "Helper",
    icon: <IconHelper />,
    link: routes.HELPER,
  },
];

const Navigation: FC = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.navs}>
          {map(navs, (item) => (
            <NavLink
              key={item.link}
              to={item.link}
              className={({ isActive, isPending }) =>
                clsx(
                  styles.btn,
                  isActive ? styles.current : isPending ? styles.pending : null
                )
              }
            >
              {item.icon}
              <label className="sr-only">{item.label}</label>
            </NavLink>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Navigation;

import { Circle as CircleRef } from "konva/lib/shapes/Circle";
import { Label as LabelRef } from "konva/lib/shapes/Label";
import map from "lodash-es/map";
import split from "lodash-es/split";
import { FC, useEffect, useRef, useState } from "react";
import { Circle, Label, Line, Tag, Text } from "react-konva";
import paperSize from "utils/paperSize";
import strategiesTreeModelConfig from "utils/strategiesTreeModelConfig";

type TStrategiesTreeTextProps = {
  data: string;
};

const distance =
  strategiesTreeModelConfig.fontSize *
  (strategiesTreeModelConfig.lineHeight * 2);

type TItemProps = {
  keyIdx: number;
  data: string;
  str: string;
};

const x3 = paperSize.height / 2;
const y3 = (paperSize.width * 72) / 100;
const color = "white";

const Item: FC<TItemProps> = ({ keyIdx, data, str }) => {
  const labelRef = useRef<LabelRef | null>(null);
  const circleRef = useRef<CircleRef | null>(null);
  const [points, setPoints] = useState<number[]>([]);

  useEffect(() => {
    if (labelRef.current && circleRef.current) {
      const x1 =
        labelRef.current.getPosition().x + labelRef.current.getHeight() / 2;
      const y1 = labelRef.current.getPosition().y - labelRef.current.getWidth();
      circleRef.current.setPosition({
        x: labelRef.current.getPosition().x + labelRef.current.getHeight() / 2,
        y: labelRef.current.getPosition().y - labelRef.current.getWidth(),
      });
      setPoints([x1, y1, x1, y3, x3, y3]);
    }
  }, []);

  return (
    <>
      <Circle ref={circleRef} radius={2} fill={color} />
      <Line points={points} stroke={color} strokeWidth={1} lineJoin="round" />
      <Label
        ref={labelRef}
        y={paperSize.width - 50}
        x={keyIdx * distance + (125 + (14 - split(data, "\n").length))}
        align="right"
        rotationDeg={-90}
      >
        <Tag fill={color} opacity={0.4} />
        <Text
          text={str}
          fontSize={strategiesTreeModelConfig.fontSize}
          lineHeight={strategiesTreeModelConfig.lineHeight}
          align="right"
          verticalAlign="center"
          fontVariant="bold"
          padding={5}
        />
      </Label>
    </>
  );
};

const StrategiesTreeText: FC<TStrategiesTreeTextProps> = ({ data }) => {
  if (!data) {
    return null;
  }

  return (
    <>
      {map(split(data, "\n"), (str: string, key: number) => {
        if (!str) return null;
        return <Item key={key} keyIdx={key} data={data} str={str} />;
      })}
    </>
  );
};

export default StrategiesTreeText;

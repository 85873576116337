import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import authorImg from "shared/images/house_model_author.png";
import goalsImg from "shared/images/house_model_goals.png";
import resourcesImg from "shared/images/house_model_resources.png";
import shineImg from "shared/images/house_model_shine.png";
import stairsImg from "shared/images/house_model_stairs.png";
import stormImg from "shared/images/house_model_storm.png";
import valuesImg from "shared/images/house_model_values.png";
import houseModelImg from "shared/images/house_model.jpg";
import routes from "utils/routes";
import styles from "./HouseModelSidebar.module.scss";
import map from "lodash-es/map";
import clsx from "clsx";

type TModel = {
  img: string;
  link: string;
};

const listOfModules: Array<TModel> = [
  {
    img: houseModelImg,
    link: routes.HOUSE_MODEL_DASHBOARD,
  },
  {
    img: authorImg,
    link: routes.HOUSE_MODEL_AUTHOR,
  },
  {
    img: resourcesImg,
    link: routes.HOUSE_MODEL_RESOURCES,
  },
  {
    img: shineImg,
    link: routes.HOUSE_MODEL_SHINE,
  },
  {
    img: stormImg,
    link: routes.HOUSE_MODEL_STORM,
  },
  {
    img: valuesImg,
    link: routes.HOUSE_MODEL_VALUES,
  },
  {
    img: goalsImg,
    link: routes.HOUSE_MODEL_GOALS,
  },
  {
    img: stairsImg,
    link: routes.HOUSE_MODEL_STAIRS,
  },
];

const HouseModelSidebar: FC = () => {
  const location = useLocation();

  return (
    <div className={styles.wrapper}>
      {map(listOfModules, (item: TModel) => (
        <NavLink
          className={clsx(
            styles.btn,
            location.pathname === routes.HOUSE_MODEL + item.link
              ? styles.current
              : null
          )}
          to={routes.HOUSE_MODEL + item.link}
          key={item.link}
        >
          <div className={styles.imgWrap}>
            <img src={item.img} alt="" />
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default HouseModelSidebar;

import { useAppDispatch, useAppSelector } from "features/hooks";
import { setDataHouseModelValues } from "features/houseModel";
import { RootState } from "features/store";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import styles from "../Forms.module.scss";

const HouseModelValuesForm: FC = () => {
  const dispatch = useAppDispatch();
  const data: string = useAppSelector(
    (state: RootState) => state.houseModel.data.values
  );
  const [value, setValue] = useState<string>(data);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setDataHouseModelValues(value));
    alert("Đã lưu!");
  };

  return (
    <div className={styles.contentWrap}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          <span className={styles.strong}>Sứ mệnh</span> nền tảng
        </h3>
        <p className={styles.subtitle}>
          3 từ chỉ câu trả lời cho mọi việc bạn làm
        </p>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formControl}>
            <label className={styles.label}>
              Đâu là các giá trị nền tảng bạn?
            </label>
            <textarea
              className={styles.input}
              name="values"
              onChange={handleChange}
              rows={3}
            >
              {value}
            </textarea>
          </div>
          <button type="submit" className={styles.btn}>
            Lưu lại
          </button>
        </form>
      </div>
    </div>
  );
};

export default HouseModelValuesForm;

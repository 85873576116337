import { FC, useRef } from "react";
import { Layer, Rect, Stage } from "react-konva";
import { Stage as StageRef } from "konva/lib/Stage";
import paperSize from "utils/paperSize";
import styles from "./StrategiesTreeModel.module.scss";
import DownloadButton from "components/HouseModel/DownloadButton";
import StrategiesTreeModelImg from "./StrategiesTreeModelImg";
import StrategiesTreeText from "./StrategiesTreeText";
import { useAppSelector } from "features/hooks";
import { RootState } from "features/store";

const StrategiesTreeModel: FC = () => {
  const data: string = useAppSelector(
    (state: RootState) => state.strategiesTreeModel.data.branches
  );
  const stageRef = useRef<StageRef | null>(null);

  const downloadURI = (uri: string, name: string) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
  };

  const handleClick = () => {
    if (stageRef.current) {
      const dataURL = stageRef.current.toDataURL({ pixelRatio: 3 });
      downloadURI(dataURL, "stage.png");
    }
  };

  return (
    <>
      <Stage
        width={paperSize.height}
        height={paperSize.width}
        className={styles.wrapper}
        ref={stageRef}
      >
        <Layer>
          <Rect
            width={paperSize.height}
            height={paperSize.width}
            x={0}
            y={0}
            fill="white"
          />
          <StrategiesTreeModelImg />
          <StrategiesTreeText data={data} />
        </Layer>
      </Stage>
      <DownloadButton onClick={handleClick} />
    </>
  );
};

export default StrategiesTreeModel;

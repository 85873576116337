import { FC } from "react";
import { Circle, Group, Line, Text } from "react-konva";
import houseModelConfig from "utils/houseModelConfig";
import paperSize from "utils/paperSize";

type THouseShineProps = {
  text?: string;
};

const fontSize = houseModelConfig.fontSize;
const lineHeight = houseModelConfig.lineHeight;
const textColor = "black";
const guildeCircleSize = 2;
const guildeLineColor = "black";
const x = paperSize.width - paperSize.width / 4.5 - 10;
const y = (paperSize.height * 6) / 100;
const guildePoints = [-10, fontSize / 2, -30, fontSize / 2, -90, 90];
const w = paperSize.width / 4.5;
const h = fontSize * lineHeight * 13;

const HouseShine: FC<THouseShineProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Group x={x} y={y}>
      <Circle
        x={guildePoints[0]}
        y={guildePoints[1]}
        radius={guildeCircleSize}
        fill={guildeLineColor}
      />
      <Circle
        x={guildePoints[4]}
        y={guildePoints[5]}
        radius={guildeCircleSize}
        fill={guildeLineColor}
      />
      <Line
        points={guildePoints}
        stroke={guildeLineColor}
        strokeWidth={1}
        lineJoin="round"
        dash={[3, 2]}
      />
      <Text
        width={w}
        height={h}
        fontSize={fontSize}
        lineHeight={lineHeight}
        text={text}
        fill={textColor}
      />
    </Group>
  );
};

export default HouseShine;

import { useAppDispatch, useAppSelector } from "features/hooks";
import { RootState } from "features/store";
import { setDataStrategiesModelBranches } from "features/strategiresTreeModel";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import styles from "../StrategiesTreeModelForm.module.scss";

const StrategiesTreeModelBranchesForm: FC = () => {
  const dispatch = useAppDispatch();
  const data: string = useAppSelector(
    (state: RootState) => state.strategiesTreeModel.data.branches
  );
  const [value, setValue] = useState<string>(data);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setDataStrategiesModelBranches(value));
    alert("Đã lưu!");
  };

  return (
    <div className={styles.contentWrap}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          Những <span className={styles.strong}>phẩm chất</span> của tôi
        </h3>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formControl}>
            <label className={styles.label}>
              Đâu là những phẩm chất của bạn?{" "}
              <small>
                <i>
                  (Tối đa <b>15</b> phẩm chất)
                </i>
              </small>
            </label>
            <textarea
              className={styles.input}
              name="branches"
              onChange={handleChange}
              rows={15}
            >
              {value}
            </textarea>
          </div>
          <button type="submit" className={styles.btn}>
            Lưu lại
          </button>
        </form>
      </div>
    </div>
  );
};

export default StrategiesTreeModelBranchesForm;

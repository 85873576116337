import { FC } from "react";
import { Group, Label, Tag, Text } from "react-konva";
import houseModelConfig from "utils/houseModelConfig";
import paperSize from "utils/paperSize";

type TThirdStairProps = {
  text?: string;
};

const fontSize = houseModelConfig.fontSize;
const lineHeight = houseModelConfig.lineHeight;
const w = paperSize.width / 6;
const h = fontSize * lineHeight * 3;
const x = (paperSize.width - w) / 2 + 115;
const y = (paperSize.height * 59) / 100;

const ThirdStair: FC<TThirdStairProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Group x={x} y={y}>
      <Label>
        <Tag
          fill="#E6B400"
          opacity={0.8}
          pointerDirection="right"
          pointerWidth={10}
          pointerHeight={5}
        />
        <Text
          width={w}
          height={h}
          fontSize={fontSize}
          lineHeight={lineHeight}
          text={text}
          fill="white"
          align="center"
          verticalAlign="center"
        />
      </Label>
    </Group>
  );
};

export default ThirdStair;

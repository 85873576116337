import { useAppDispatch, useAppSelector } from "features/hooks";
import { RootState } from "features/store";
import { setDataSWOTModelOpportunities } from "features/swotModel";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import styles from "../SWOTForms.module.scss";

const SWOTModelOForm: FC = () => {
  const dispatch = useAppDispatch();
  const data: string = useAppSelector(
    (state: RootState) => state.swotModel.data.opportunities
  );
  const [value, setValue] = useState<string>(data);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setDataSWOTModelOpportunities(value));
    alert("Đã lưu!");
  };

  return (
    <div className={styles.contentWrap}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          <span className={styles.strong}>Cơ hội</span> của tôi
        </h3>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formControl}>
            <label className={styles.label}>
              Bạn đang nhìn thấy những cơ hội gì?
            </label>
            <textarea
              className={styles.input}
              name="opportunities"
              onChange={handleChange}
              rows={25}
            >
              {value}
            </textarea>
          </div>
          <button type="submit" className={styles.btn}>
            Lưu lại
          </button>
        </form>
      </div>
    </div>
  );
};

export default SWOTModelOForm;

const routes = {
  DASHBOARD: "/",
  HOUSE_MODEL: "/house-model",
  HOUSE_MODEL_DASHBOARD: "",
  HOUSE_MODEL_VALUES: "/values",
  HOUSE_MODEL_SHINE: "/shine",
  HOUSE_MODEL_STORM: "/storm",
  HOUSE_MODEL_GOALS: "/goals",
  HOUSE_MODEL_STAIRS: "/stairs",
  HOUSE_MODEL_RESOURCES: "/resources",
  HOUSE_MODEL_AUTHOR: "/author",
  SWOT_MODEL: "/swot-model",
  SWOT_MODEL_DASHBOARD: "",
  SWOT_MODEL_STRENGTHS: "/strengths",
  SWOT_MODEL_WEAKNESSES: "/weaknesses",
  SWOT_MODEL_OPPORTUNITIES: "/opportunities",
  SWOT_MODEL_THREATS: "/threats",
  STRATEGIES_TREE_MODEL: "/strategies-tree-model",
  STRATEGIES_TREE_MODEL_DASHBOARD: "",
  STRATEGIES_TREE_MODEL_BRANCHS: "/branchs",
  HELPER: "/helper",
};

export default routes;

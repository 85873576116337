import { FC } from "react";
import { Label, Tag, Text } from "react-konva";
import houseModelConfig from "utils/houseModelConfig";

type THouseAuthorProps = {
  fullname?: string;
  createdAt?: string;
};

const fontSize = houseModelConfig.fontSize;
const lineHeight = houseModelConfig.lineHeight;
const x = 10;
const y = 10;

const HouseAuthor: FC<THouseAuthorProps> = ({ fullname, createdAt }) => {
  return (
    <Label x={x} y={y}>
      <Tag fill="#ffff89" stroke="black" strokeWidth={1} />
      <Text
        x={10}
        y={10}
        text={`Người lập: ${fullname || "N/A"}\nNgày: ${createdAt || "N/A"}`}
        fontSize={fontSize}
        lineHeight={lineHeight}
        fontVariant="bold"
        padding={4}
      />
    </Label>
  );
};

export default HouseAuthor;

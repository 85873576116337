import { FC } from "react";
import { Image } from "react-konva";
import useImage from "use-image";
import img from "shared/images/house_model.jpg";
import paperSize from "utils/paperSize";

const HouseModelImg: FC = () => {
  const [image] = useImage(img);
  return (
    <Image
      x={(paperSize.width - paperSize.sizeImageHero) / 2}
      y={(paperSize.height - paperSize.sizeImageHero) / 2}
      image={image}
      width={paperSize.sizeImageHero}
      height={paperSize.sizeImageHero}
    />
  );
};

export default HouseModelImg;

import { FC } from "react";
import { Outlet } from "react-router-dom";
import SWOTModelSidebar from "../SWOTModelSidebar";
import styles from "./SWOTModelRoot.module.scss";

const SWOTModelRoot: FC = () => {
  return (
    <>
      <SWOTModelSidebar />
      <div className={styles.pageWrap}>
        <Outlet />
      </div>
    </>
  );
};

export default SWOTModelRoot;

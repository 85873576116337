import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  THouseModel,
  THouseModelAuthor,
  THouseModelGoals,
  THouseModelStairs,
  THouseModelState,
} from "./houseModel.types";

const dataDefault: THouseModel = {
  author: {
    fullname: "",
    createdAt: "",
  },
  resources: "",
  shines: "",
  storm: "",
  values: "",
  goals: {
    first: "",
    second: "",
    third: "",
    fourth: "",
    last: "",
  },
  stairs: {
    first: "",
    second: "",
    third: "",
    fourth: "",
    last: "",
  },
};

// Define the initial state using that type
const initialState: THouseModelState = {
  data: dataDefault,
};

export const houseModelSlice = createSlice({
  name: "houseModel",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDataHouseModelStairs: (
      state,
      action: PayloadAction<THouseModelStairs>
    ) => {
      state.data.stairs = action.payload;
    },
    setDataHouseModelGoals: (
      state,
      action: PayloadAction<THouseModelGoals>
    ) => {
      state.data.goals = action.payload;
    },
    setDataHouseModelValues: (state, action: PayloadAction<string>) => {
      state.data.values = action.payload;
    },
    setDataHouseModelStorm: (state, action: PayloadAction<string>) => {
      state.data.storm = action.payload;
    },
    setDataHouseModelShines: (state, action: PayloadAction<string>) => {
      state.data.shines = action.payload;
    },
    setDataHouseModelResources: (state, action: PayloadAction<string>) => {
      state.data.resources = action.payload;
    },
    setDataHouseModelAuthor: (
      state,
      action: PayloadAction<THouseModelAuthor>
    ) => {
      state.data.author = action.payload;
    },
    setDataHouseModel: (state, action: PayloadAction<THouseModel>) => {
      state.data = action.payload;
    },
  },
});

export const {
  setDataHouseModel,
  setDataHouseModelAuthor,
  setDataHouseModelResources,
  setDataHouseModelShines,
  setDataHouseModelStorm,
  setDataHouseModelValues,
  setDataHouseModelGoals,
  setDataHouseModelStairs,
} = houseModelSlice.actions;

export default houseModelSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TSWOTModel, TSWOTModelState } from "./swotModel.types";

const dataDefault: TSWOTModel = {
  strengths: "",
  weaknesses: "",
  opportunities: "",
  threats: "",
};

// Define the initial state using that type
const initialState: TSWOTModelState = {
  data: dataDefault,
};

export const swotModelSlice = createSlice({
  name: "swotModel",
  // `createSlice` will infer the state type from the `initialState` argument
  initialState,
  reducers: {
    setDataSWOTModelThreats: (state, action: PayloadAction<string>) => {
      state.data.threats = action.payload;
    },
    setDataSWOTModelOpportunities: (state, action: PayloadAction<string>) => {
      state.data.opportunities = action.payload;
    },
    setDataSWOTModelWeaknesses: (state, action: PayloadAction<string>) => {
      state.data.weaknesses = action.payload;
    },
    setDataSWOTModelStrengths: (state, action: PayloadAction<string>) => {
      state.data.strengths = action.payload;
    },
    setDataSWOTModel: (state, action: PayloadAction<TSWOTModel>) => {
      state.data = action.payload;
    },
  },
});

export const {
  setDataSWOTModelThreats,
  setDataSWOTModelOpportunities,
  setDataSWOTModelWeaknesses,
  setDataSWOTModelStrengths,
  setDataSWOTModel,
} = swotModelSlice.actions;

export default swotModelSlice.reducer;

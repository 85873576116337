import { FC, ReactNode } from "react";
import styles from "./Wrapper.module.scss";

type TWrapperProps = {
  children: ReactNode;
};

const Wrapper: FC<TWrapperProps> = ({ children }) => {
  return <div className={styles.pageWrap}>{children}</div>;
};

export default Wrapper;

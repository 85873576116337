import { configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import houseModelReducer from "./houseModel";
import swotModelReducer from "./swotModel";
import strategiresTreeModelReducer from "./strategiresTreeModel";

const houseModelConfig = {
  key: "houseModel",
  storage,
};

const swotModelConfig = {
  key: "swotModel",
  storage,
};

const strategiesTreeModelConfig = {
  key: "strategiesTreeModel",
  storage,
};

export const store = configureStore({
  reducer: {
    houseModel: persistReducer(houseModelConfig, houseModelReducer),
    swotModel: persistReducer(swotModelConfig, swotModelReducer),
    strategiesTreeModel: persistReducer(
      strategiesTreeModelConfig,
      strategiresTreeModelReducer
    ),
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

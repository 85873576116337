import DownloadButton from "components/HouseModel/DownloadButton";
import { useAppSelector } from "features/hooks";
import { RootState } from "features/store";
import { TSWOTModel } from "features/swotModel/swotModel.types";
import map from "lodash-es/map";
import split from "lodash-es/split";
import { FC, useRef } from "react";
import { toPng } from "html-to-image";
import styles from "./SWOTModel.module.scss";

type TModelitem = {
  label: string;
  content: string;
};

const SWOTModel: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const data: TSWOTModel = useAppSelector(
    (state: RootState) => state.swotModel.data
  );

  const swotData: Array<TModelitem> = [
    {
      label: "Điểm mạnh",
      content: data.strengths,
    },
    {
      label: "Điểm yếu",
      content: data.weaknesses,
    },
    {
      label: "Cơ hội",
      content: data.opportunities,
    },
    {
      label: "Nguy cơ",
      content: data.threats,
    },
  ];

  const handleClick = () => {
    if (ref.current) {
      toPng(ref.current, { cacheBust: true })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "my_swot.png";
          link.href = dataUrl;
          link.click();
          link.remove();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <>
      <div className={styles.pageWrap}>
        <div className={styles.container} ref={ref}>
          {map(swotData, (item: TModelitem) => (
            <div className={styles.box} key={item.label}>
              <div className={styles.header}>
                <h3>{item.label}</h3>
              </div>
              <div className={styles.content}>
                <ul className={styles.list}>
                  {map(
                    split(item.content, "\n"),
                    (text: string, key: number) => {
                      if (!text) return null;
                      return (
                        <li key={key}>
                          <span>{text}</span>
                        </li>
                      );
                    }
                  )}
                </ul>
              </div>
            </div>
          ))}
        </div>
      </div>
      <DownloadButton onClick={handleClick} />
    </>
  );
};

export default SWOTModel;

const width = 900;
const height = (width * 210) / 297;
const margin = 150;

const paperSize = {
  width,
  height,
  sizeImageHero: (height * 8) / 9,
  strategiesTreeImageHero: {
    width: 770 - margin,
    height: 983 - margin,
  },
};

export default paperSize;

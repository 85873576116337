import { clsx } from "clsx";
import map from "lodash-es/map";
import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import modelImg from "shared/images/strategies_tree.jpg";
import treeEmptyImg from "shared/images/strategies_tree_empty.jpg";
import routes from "utils/routes";
import styles from "./StrategiesTreeModelSidebar.module.scss";

type TModel = {
  img: string;
  link: string;
};

const listOfModules: Array<TModel> = [
  {
    img: modelImg,
    link: routes.STRATEGIES_TREE_MODEL_DASHBOARD,
  },
  {
    img: treeEmptyImg,
    link: routes.STRATEGIES_TREE_MODEL_BRANCHS,
  },
];

const StrategiesTreeModelSidebar: FC = () => {
  const location = useLocation();

  return (
    <div className={styles.wrapper}>
      {map(listOfModules, (item: TModel) => (
        <NavLink
          className={clsx(
            styles.btn,
            location.pathname === routes.STRATEGIES_TREE_MODEL + item.link
              ? styles.current
              : null
          )}
          to={routes.STRATEGIES_TREE_MODEL + item.link}
          key={item.link}
        >
          <div className={styles.imgWrap}>
            <img src={item.img} alt="" />
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default StrategiesTreeModelSidebar;

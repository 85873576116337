import { FC, useRef } from "react";
import { Stage, Layer, Rect } from "react-konva";
import HouseModelImg from "./HouseModelImg";
import styles from "./HouseModel.module.scss";
import HouseValues from "./HouseValues";
import HouseResources from "./HouseResources";
import HouseShine from "./HouseShine";
import HouseStorm from "./HouseStorm";
import DownloadButton from "./DownloadButton";
import { Stage as StageRef } from "konva/lib/Stage";
import FirstGoals from "./FirstGoals";
import ThirdGoals from "./ThirdGoals";
import SecondGoals from "./SecondGoals";
import FourGoals from "./FourGoals";
import Vision from "./Vision";
import HouseAuthor from "./HouseAuthor";
import dayjs from "dayjs";
import FirstStair from "./FirstStair";
import SecondStair from "./SecondStair";
import ThirdStair from "./ThirdStair";
import FourStair from "./FourStair";
import LastStair from "./LastStair";
import { useAppSelector } from "features/hooks";
import { RootState } from "features/store";
import { THouseModel } from "features/houseModel/houseModel.types";
import paperSize from "utils/paperSize";

const HouseModel: FC = () => {
  const data: THouseModel = useAppSelector(
    (state: RootState) => state.houseModel.data
  );
  const stageRef = useRef<StageRef | null>(null);

  const downloadURI = (uri: string, name: string) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
  };

  const handleClick = () => {
    if (stageRef.current) {
      const dataURL = stageRef.current.toDataURL({ pixelRatio: 3 });
      downloadURI(dataURL, "stage.png");
    }
  };

  return (
    <>
      <Stage
        width={paperSize.width}
        height={paperSize.height}
        className={styles.wrapper}
        ref={stageRef}
      >
        <Layer>
          <Rect
            width={paperSize.width}
            height={paperSize.height}
            x={0}
            y={0}
            fill="white"
          />
          <HouseModelImg />
          <FirstStair text={data.stairs?.first} />
          <SecondStair text={data.stairs?.second} />
          <ThirdStair text={data.stairs?.third} />
          <FourStair text={data.stairs?.fourth} />
          <LastStair text={data.stairs?.last} />
          <HouseValues text={data.values} />
          <HouseResources text={data.resources} />
          <HouseShine text={data.shines} />
          <HouseStorm text={data.storm} />
          <FirstGoals text={data.goals?.first} />
          <SecondGoals text={data.goals?.second} />
          <ThirdGoals text={data.goals?.third} />
          <FourGoals text={data.goals?.fourth} />
          <Vision text={data.goals?.last} />
          <HouseAuthor
            fullname={data.author.fullname}
            createdAt={dayjs(data.author.createdAt).format("DD/MM/YYYY HH:mm")}
          />
        </Layer>
      </Stage>
      <DownloadButton onClick={handleClick} />
    </>
  );
};

export default HouseModel;

import map from "lodash-es/map";
import { FC } from "react";
import { NavLink } from "react-router-dom";
import houseModelImg from "shared/images/house_model.jpg";
import swotImg from "shared/images/swot.png";
import treeModelImg from "shared/images/strategies_tree.jpg";
import routes from "utils/routes";
import styles from "./Dashboard.module.scss";

type TModel = {
  label: string;
  img: string;
  link: string;
};

const listOfModels: Array<TModel> = [
  {
    label: "Mô hình ngôi nhà Swiss Create cho người làm việc",
    img: houseModelImg,
    link: routes.HOUSE_MODEL,
  },
  {
    label: "Bản tổng hợp dữ kiện",
    img: swotImg,
    link: routes.SWOT_MODEL,
  },
  {
    label: "Kế hoạch của tôi cho tương lai sáng hơn",
    img: treeModelImg,
    link: routes.STRATEGIES_TREE_MODEL,
  },
];

const Dashboard: FC = () => {
  return (
    <div className={styles.listOfModels}>
      {map(listOfModels, (item: TModel) => (
        <NavLink className={styles.itemList} to={item.link} key={item.link}>
          <div className={styles.imgWrap}>
            <img src={item.img} alt={item.label} className={styles.img} />
          </div>
          <h3 className={styles.label}>{item.label}</h3>
        </NavLink>
      ))}
    </div>
  );
};

export default Dashboard;

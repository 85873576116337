import { FC } from "react";
import { Label, Tag, Text } from "react-konva";
import houseModelConfig from "utils/houseModelConfig";
import paperSize from "utils/paperSize";

type TFirstStairProps = {
  text?: string;
};

const fontSize = houseModelConfig.fontSize;
const lineHeight = houseModelConfig.lineHeight;
const w = paperSize.width / 6;
const h = fontSize * lineHeight * 3;
const x = (paperSize.width - w) / 2 + 130;
const y = (paperSize.height * 72.5) / 100;

const FirstStair: FC<TFirstStairProps> = ({ text }) => {
  if (!text) {
    return null;
  }
  return (
    <Label x={x} y={y}>
      <Tag
        fill="#1653b6"
        opacity={0.8}
        pointerDirection="right"
        pointerWidth={10}
        pointerHeight={5}
      />
      <Text
        width={w}
        height={h}
        fontSize={fontSize}
        lineHeight={lineHeight}
        text={text}
        fill="white"
        align="center"
        verticalAlign="center"
      />
    </Label>
  );
};

export default FirstStair;

import { FC } from "react";
import { Outlet } from "react-router-dom";
import HouseModelSidebar from "../HouseModelSidebar";
import styles from "./HouseModelRoot.module.scss";

const HouseModelRoot: FC = () => {
  return (
    <>
      <HouseModelSidebar />
      <div className={styles.pageWrap}>
        <Outlet />
      </div>
    </>
  );
};

export default HouseModelRoot;

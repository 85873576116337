import { FC } from "react";
import { Text } from "react-konva";
import map from "lodash-es/map";
import paperSize from "utils/paperSize";
import houseModelConfig from "utils/houseModelConfig";
import split from "lodash-es/split";

type THouseValuesProps = {
  text?: string;
};

const fontSize = (houseModelConfig.fontSize * 3) / 2;
const lineHeight = houseModelConfig.lineHeight;
const w = paperSize.width / 4;
const x = (paperSize.width - w) / 2 - 20;

const HouseValues: FC<THouseValuesProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <>
      {map(split(text, "\n"), (label: string, key: number) => (
        <Text
          key={key}
          width={w}
          align="center"
          fontSize={fontSize}
          lineHeight={lineHeight}
          x={x}
          y={
            paperSize.height -
            ((paperSize.height * 11) / 100 +
              key * ((paperSize.height * 5.9) / 100))
          }
          text={label}
          fill="black"
        />
      ))}
    </>
  );
};

export default HouseValues;

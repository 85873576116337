import { useAppDispatch, useAppSelector } from "features/hooks";
import { setDataHouseModelAuthor } from "features/houseModel";
import { THouseModelAuthor } from "features/houseModel/houseModel.types";
import { RootState } from "features/store";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import styles from "../Forms.module.scss";

const HouseModelAuthorForm: FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(
    (state: RootState) => state.houseModel.data.author
  );
  const [values, setValues] = useState<THouseModelAuthor>(data);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setDataHouseModelAuthor(values));
    alert("Đã lưu!");
  };

  return (
    <div className={styles.contentWrap}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          <span className={styles.strong}>Định vị</span> bản thân
        </h3>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formControl}>
            <label className={styles.label}>Họ và tên</label>
            <input
              className={styles.input}
              name="fullname"
              type="text"
              value={values.fullname}
              onChange={handleChange}
            />
          </div>
          <div className={styles.formControl}>
            <label className={styles.label}>Ngày lập</label>
            <input
              className={styles.input}
              name="createdAt"
              type="datetime-local"
              value={values.createdAt}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className={styles.btn}>
            Lưu lại
          </button>
        </form>
      </div>
    </div>
  );
};

export default HouseModelAuthorForm;

import { FC } from "react";
import styles from "./DownloadButton.module.scss";

type TDownloadButtonProps = {
  onClick: () => void;
};

const DownloadButton: FC<TDownloadButtonProps> = ({ onClick }) => {
  return (
    <div className={styles.buttonWrap}>
      <button className={styles.button} type="button" onClick={onClick}>
        Download
      </button>
    </div>
  );
};

export default DownloadButton;

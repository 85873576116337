import { FC } from "react";
import { Group, Line, Text, Circle } from "react-konva";
import houseModelConfig from "utils/houseModelConfig";
import paperSize from "utils/paperSize";

type THouseStormProps = {
  text?: string;
};

const fontSize = houseModelConfig.fontSize;
const lineHeight = houseModelConfig.lineHeight;
const textColor = "black";
const guildeCircleSize = 2;
const guildeLineColor = "black";
const x = 10;
const y = (paperSize.height * 12) / 100;
const w = paperSize.width / 5.5;
const h = fontSize * lineHeight * 13;
const guildePoints = [
  paperSize.width / 5.5 + 10,
  fontSize / 2,
  paperSize.width / 5.5 + 30,
  fontSize / 2,
  paperSize.width / 5.5 + 80,
  140,
];

const HouseStorm: FC<THouseStormProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Group x={x} y={y}>
      <Circle
        x={guildePoints[0]}
        y={guildePoints[1]}
        radius={guildeCircleSize}
        fill={guildeLineColor}
      />
      <Circle
        x={guildePoints[4]}
        y={guildePoints[5]}
        radius={guildeCircleSize}
        fill={guildeLineColor}
      />
      <Line
        points={guildePoints}
        stroke={guildeLineColor}
        strokeWidth={1}
        lineJoin="round"
        dash={[3, 2]}
      />
      <Text
        width={w}
        height={h}
        align="right"
        fontSize={fontSize}
        lineHeight={lineHeight}
        text={text}
        fill={textColor}
      />
    </Group>
  );
};

export default HouseStorm;

import { clsx } from "clsx";
import map from "lodash-es/map";
import { FC } from "react";
import { NavLink, useLocation } from "react-router-dom";
import modelImg from "shared/images/swot.png";
import strengthsImg from "shared/images/swot_s.png";
import weaknessesImg from "shared/images/swot_w.png";
import opportunitiesImg from "shared/images/swot_o.png";
import threatsImg from "shared/images/swot_t.png";
import routes from "utils/routes";
import styles from "./SWOTModelSidebar.module.scss";

type TModel = {
  img: string;
  link: string;
};

const listOfModules: Array<TModel> = [
  {
    img: modelImg,
    link: routes.SWOT_MODEL_DASHBOARD,
  },
  {
    img: strengthsImg,
    link: routes.SWOT_MODEL_STRENGTHS,
  },
  {
    img: weaknessesImg,
    link: routes.SWOT_MODEL_WEAKNESSES,
  },
  {
    img: opportunitiesImg,
    link: routes.SWOT_MODEL_OPPORTUNITIES,
  },
  {
    img: threatsImg,
    link: routes.SWOT_MODEL_THREATS,
  },
];

const SWOTModelSidebar: FC = () => {
  const location = useLocation();

  return (
    <div className={styles.wrapper}>
      {map(listOfModules, (item: TModel) => (
        <NavLink
          className={clsx(
            styles.btn,
            location.pathname === routes.SWOT_MODEL + item.link
              ? styles.current
              : null
          )}
          to={routes.SWOT_MODEL + item.link}
          key={item.link}
        >
          <div className={styles.imgWrap}>
            <img src={item.img} alt="" />
          </div>
        </NavLink>
      ))}
    </div>
  );
};

export default SWOTModelSidebar;

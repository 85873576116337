import { FC } from "react";
import { Label, Tag, Text } from "react-konva";
import houseModelConfig from "utils/houseModelConfig";
import paperSize from "utils/paperSize";

type TLastStairProps = {
  text?: string;
};

const fontSize = houseModelConfig.fontSize;
const lineHeight = houseModelConfig.lineHeight;
const w = paperSize.width / 6;
const h = fontSize * lineHeight * 3;
const x = (paperSize.width - w) / 2 + 10;
const y = (paperSize.height * 43) / 100;

const LastStair: FC<TLastStairProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Label x={x} y={y}>
      <Tag
        fill="#D0312D"
        opacity={0.8}
        pointerDirection="down"
        pointerWidth={5}
        pointerHeight={10}
      />
      <Text
        width={w}
        height={h}
        fontSize={fontSize}
        lineHeight={lineHeight}
        text={text}
        fill="white"
        align="center"
        verticalAlign="center"
      />
    </Label>
  );
};

export default LastStair;

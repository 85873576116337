import { useAppDispatch, useAppSelector } from "features/hooks";
import { setDataHouseModelResources } from "features/houseModel";
import { RootState } from "features/store";
import { ChangeEvent, FC, FormEvent, useState } from "react";
import styles from "../Forms.module.scss";

const HouseModelResourcesForm: FC = () => {
  const dispatch = useAppDispatch();
  const data: string = useAppSelector(
    (state: RootState) => state.houseModel.data.resources
  );
  const [value, setValue] = useState<string>(data);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(setDataHouseModelResources(value));
    alert("Đã lưu!");
  };

  return (
    <div className={styles.contentWrap}>
      <div className={styles.container}>
        <h3 className={styles.title}>
          Những <span className={styles.strong}>nguồn lực</span> nào phải liên
          tục cung cấp cho ngôi nhà của bạn?
        </h3>
        <form className={styles.form} onSubmit={handleSubmit}>
          <div className={styles.formControl}>
            <label className={styles.label}>Nguồn lực</label>
            <textarea
              className={styles.input}
              name="resouces"
              onChange={handleChange}
              rows={3}
            >
              {value}
            </textarea>
          </div>
          <button type="submit" className={styles.btn}>
            Lưu lại
          </button>
        </form>
      </div>
    </div>
  );
};

export default HouseModelResourcesForm;

import { FC } from "react";
import { Label, Tag, Text } from "react-konva";
import houseModelConfig from "utils/houseModelConfig";
import paperSize from "utils/paperSize";

type TSecondStairProps = {
  text?: string;
};

const fontSize = houseModelConfig.fontSize;
const lineHeight = houseModelConfig.lineHeight;
const w = paperSize.width / 6;
const h = fontSize * lineHeight * 3;
const x = (paperSize.width - w) / 2 - 20;
const y = (paperSize.height * 66) / 100;

const SecondStair: FC<TSecondStairProps> = ({ text }) => {
  if (!text) {
    return null;
  }

  return (
    <Label x={x} y={y}>
      <Tag
        fill="#03AC13"
        opacity={0.8}
        pointerDirection="left"
        pointerWidth={10}
        pointerHeight={5}
      />
      <Text
        width={w}
        height={h}
        fontSize={fontSize}
        lineHeight={lineHeight}
        text={text}
        fill="white"
        align="center"
        verticalAlign="center"
      />
    </Label>
  );
};

export default SecondStair;

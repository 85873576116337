import { FC } from "react";
import { Image } from "react-konva";
import useImage from "use-image";
import img from "shared/images/strategies_tree.jpg";
import paperSize from "utils/paperSize";

const StrategiesTreeModelImg: FC = () => {
  const [image] = useImage(img);
  return (
    <Image
      x={(paperSize.height - paperSize.strategiesTreeImageHero.width) / 2}
      y={(paperSize.width - paperSize.strategiesTreeImageHero.height) / 2}
      image={image}
      width={paperSize.strategiesTreeImageHero.width}
      height={paperSize.strategiesTreeImageHero.height}
    />
  );
};

export default StrategiesTreeModelImg;
